/*
 * Name: Toggle.js
 * Description: Toggle class `data-toggle-class` of elements `data-toggle-element` optionally within a specific group `data-toggle-group`
 * Version: v1.0.1
 */

DOMTokenList.prototype.addMany = function (classes) {
    let array = classes.split(' ');
    for (let i = 0, length = array.length; i < length; i++) {
        this.add(array[i]);
    }
}

DOMTokenList.prototype.removeMany = function (classes) {
    let array = classes.split(' ');
    for (let i = 0, length = array.length; i < length; i++) {
        this.remove(array[i]);
    }
}

DOMTokenList.prototype.toggleMany = function (classes) {
    let array = classes.split(' ');
    for (let i = 0, length = array.length; i < length; i++) {
        this.toggle(array[i]);
    }
}

const on = (selector, eventType, childSelector, eventHandler) => {
    const elements = document.querySelectorAll(selector)
    for (let element of elements) {
        element.addEventListener(eventType, eventOnElement => {
            if (eventOnElement.target.matches(childSelector)) {
                eventHandler(eventOnElement)
            }
        })
    }
}

window.onload = function () {
    document.addEventListener('click', function (e) {

        let togglePreventDefault = e.target.getAttribute('data-toggle-prevent-default');
        if(togglePreventDefault) {
            e.preventDefault();
        }

        let outsideElements = document.querySelectorAll('[data-toggle-hide-outside]');

        [].forEach.call(outsideElements, function (outsideElement) {
            let outsideElementGroup = outsideElement.getAttribute('data-toggle-group');
            let outsideElementToggleClass = outsideElement.getAttribute('data-toggle-class');

            if (outsideElement.classList.contains(outsideElementToggleClass) === true || outsideElement.classList.contains('active') === true) {
                if (!outsideElement.contains(e.target)) {
                    let outsideElementTarget = outsideElement.getAttribute('data-toggle-element');
                    let outsideElementTargetDiv = document.querySelector(outsideElementTarget);
                    if (outsideElementGroup) {
                        outsideElementTargetDiv = document.querySelector(outsideElementTarget + '[data-toggle-group="' + outsideElementGroup + '"]');
                    }

                    outsideElement.classList.removeMany(outsideElementToggleClass);
                    outsideElementTargetDiv.classList.removeMany(outsideElementToggleClass);
                }
            }

        });
    });

    on('body', 'click', '[data-toggle-element]', toggleElements);
};

function toggleElements(e) {
    let togglePreventDefault = e.target.getAttribute('data-toggle-prevent-default');
    if(togglePreventDefault) {
        e.preventDefault();
        e.stopPropagation();
        e.target.closest('[data-toggle-class]').click();
    }

    // Targets
    let toggleGroup = e.target.getAttribute('data-toggle-group');
    let toggleElement = e.target.getAttribute('data-toggle-element');

    let focusSelector = e.target.getAttribute('data-toggle-focus');
    let focusElement = document.querySelector(focusSelector);

    // Class
    let toggleClass = e.target.getAttribute('data-toggle-class');

    let toggleElementDiv = document.querySelector(toggleElement);

    if (toggleGroup) {
        toggleElementDiv = document.querySelector(toggleElement + '[data-toggle-group="' + toggleGroup + '"]');
    }

    // If data-toggle-element is true
    if (toggleElement) {

        // If data-toggle-group is true
        if (toggleGroup) {

            // If data-toggle-element contains data-toggle-class
            if (toggleElementDiv.classList.contains(toggleClass)) {

                let toggleGroupDiv = document.querySelectorAll('[data-toggle-group="' + toggleGroup + '"]');
                let toggleGroupTargets = document.querySelectorAll('[data-toggle-group="' + toggleGroup + '"]:not( ' + toggleElement + ' )');

                // Remove data-toggle-class from data-toggle-element
                for (let i = 0; i < toggleGroupDiv.length; i++) {
                    let toggleGroups = toggleGroupDiv[i];
                    toggleGroups.classList.removeMany(toggleClass);
                }

                // Remove data-toggle-class from target
                for (let i = 0; i < toggleGroupTargets.length; i++) {
                    let toggleGroups = toggleGroupTargets[i];
                    toggleGroups.classList.removeMany('active');
                }

                // Console
                if (window.g_dev === true) {
                    console.log('Toggle groups exists "' + toggleGroup + '" and element "' + toggleElement + '" doesn\'t contain toggle class "' + toggleClass + '".');
                }

            // If data-toggle-element doesn't contain data-toggle-class
            } else {

                let toggleGroupDiv = document.querySelectorAll('[data-toggle-group="' + toggleGroup + '"]');
                let toggleGroupTargets = document.querySelectorAll('[data-toggle-group="' + toggleGroup + '"]:not( ' + toggleElement + ' )');

                // Remove data-toggle-class from all data-toggle-element and add only to targeted
                for (let i = 0; i < toggleGroupDiv.length; i++) {
                    let toggleGroups = toggleGroupDiv[i];
                    toggleGroups.classList.removeMany(toggleClass);
                }
                toggleElementDiv.classList.addMany(toggleClass);

                // Remove data-toggle-class from all targets and add only to targeted
                for (let i = 0; i < toggleGroupTargets.length; i++) {
                    let toggleGroups = toggleGroupTargets[i];
                    toggleGroups.classList.removeMany('active');
                }
                e.target.classList.addMany('active');

                // Console
                if (window.g_dev === true) {
                    console.log('Toggle groups exists "' + toggleGroup + '" and element "' + toggleElement + '" does contain toggle class "' + toggleClass + '".');
                }
            }

            // If data-toggle-group is false
        } else {

            // Toggle data-toggle-class from data-toggle-element
            toggleElementDiv.classList.toggleMany(toggleClass);

            // Toggle data-toggle-class from target
            e.target.classList.toggleMany('active');

            // focus toggle element
            if (focusElement) {
                focusElement.focus();
            }

            // Console
            if (window.g_dev === true) {
                console.log('No toggle group and element "' + toggleElement + '" with toggle class "' + toggleClass + '".');
            }

        }
    }
}
