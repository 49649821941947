/*
 * Name: Mobile-menu
 * Description: Enable mobile menu when header menu is too large
 * Version: v1.0.0
 */

menuWidth();

window.addEventListener('resize', function(event){
    menuWidth()
});

function menuWidth() {
    jsResetMobileMenu();

    var menuParentClass = document.querySelector('[data-menu-wrapper]').getAttribute('data-menu-wrapper');
    var menuParentWidth = document.querySelector('[data-menu-wrapper]').offsetWidth;
    var menuChildrenWidth = 0;
    var elements = document.querySelectorAll('[data-menu-child]');

    Array.prototype.forEach.call(elements, function(el, i){
        menuChildrenWidth += el.offsetWidth;
    });

    if(window.g_windowWidth > 992) {
        if(menuChildrenWidth > menuParentWidth) {
            if(g_dev === false) {
                console.log('Menu width: '+menuChildrenWidth);
                console.log('Wrapper width: '+menuParentWidth);
                console.log('Menu too large');
            }
            jsShowMobileMenu();
        } else {
            jsHideMobileMenu();
        }
    }

    function jsShowMobileMenu() {
        document.querySelector('body').classList.add('js-mobile-header');

        var elems = document.querySelectorAll('[data-menu-show="mobile"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.add('js-show');
        });
        var elems = document.querySelectorAll('[data-menu-show="desktop"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.add('js-hide');
        });
    }

    function jsHideMobileMenu() {
        document.querySelector('body').classList.remove('js-mobile-header');

        var elems = document.querySelectorAll('[data-menu-show="mobile"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.add('js-hide');
        });
        var elems = document.querySelectorAll('[data-menu-show="desktop"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.add('js-show');
        });
    }

    function jsResetMobileMenu() {
        var elems = document.querySelectorAll('[data-menu-show="mobile"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.remove('js-hide');
            element.classList.remove('js-show');
        });
        var elems = document.querySelectorAll('[data-menu-show="desktop"]');
        var $elems = [].slice.call(elems);
        $elems.map((element) => {
            element.classList.remove('js-hide');
            element.classList.remove('js-show');
        });
    }
}
