let headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

if(headings.length && (typeof(document.querySelector('.dev-seo .badge')) != 'undefined' && document.querySelector('.dev-seo .badge') != null)) {
    document.querySelector('.dev-seo .badge').append(headings.length);
    [].forEach.call(headings, function (heading) {
        var headingLi = document.createElement('li');
        headingLi.append(heading.tagName+': ');
        headingLi.append(heading.textContent);
        document.querySelector('.dev-seo ul').append(headingLi);
    });
}

if(headings.length && (typeof(document.querySelector('.dev-grid')) != 'undefined' && document.querySelector('.dev-grid') != null)) {
    document.querySelector('.dev-grid-toggle').addEventListener('change', (event) => {
        if (event.target.checked) {
            localStorage.setItem('dev-grid', '1');
        } else {
            localStorage.setItem('dev-grid', '0');
        }
    });

    if (localStorage.getItem('dev-grid') === '1') {
        document.querySelector('.dev-grid-toggle').checked = true;
    }
}


