const revealOnScrollElements = document.querySelectorAll('[data-reveal-on-scroll]');

// IntersectionObserver voor fade-effecten
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const element = entry.target;
        const dataReveal = element.getAttribute('data-reveal-on-scroll');
        const visibilityPercentage = entry.intersectionRatio;

        if (entry.isIntersecting) {
            // Fade-in logic
            element.style.transition = 'opacity 0.5s ease-in-out';
            element.style.opacity = `${visibilityPercentage}`;

            // Sync-elementen aanpassen
            const syncElementSelector = `[data-reveal-on-scroll-sync="${dataReveal}"]`;
            document.querySelectorAll(syncElementSelector).forEach((syncElement) => {
                syncElement.style.transition = 'opacity 0.5s ease-in-out';
                syncElement.style.opacity = `${visibilityPercentage}`;
            });
        } else {
            // Elementen die niet in beeld zijn op 0 zetten, behalve de eerste
            if (element !== revealOnScrollElements[0]) {
                element.style.opacity = '0';

                // Sync-elementen ook aanpassen
                const syncElementSelector = `[data-reveal-on-scroll-sync="${dataReveal}"]`;
                document.querySelectorAll(syncElementSelector).forEach((syncElement) => {
                    syncElement.style.opacity = '0';
                });
            }
        }
    });
}, {
    threshold: [0.0, 0.1, 0.25, 0.5, 0.75, 1.0],
});

// Scroll event listener for the first and last elements
function handleElementVisibility() {
    if (revealOnScrollElements.length === 0) {
        return; // Stop de functie als er geen elementen zijn
    }

    const firstElement = revealOnScrollElements[0];
    const lastElement = revealOnScrollElements[revealOnScrollElements.length - 1];

    // Check if the first element is visible in the viewport
    const firstElementRect = firstElement.getBoundingClientRect();
    const isFirstElementVisible = firstElementRect.bottom + window.scrollY > window.scrollY &&
        firstElementRect.top + window.scrollY < window.scrollY + window.innerHeight;

    if (isFirstElementVisible) {
        // Keep the first element always visible
        firstElement.style.opacity = '1';

        // Keep synced elements also visible
        const dataReveal = firstElement.getAttribute('data-reveal-on-scroll');
        const syncElementSelector = `[data-reveal-on-scroll-sync="${dataReveal}"]`;
        document.querySelectorAll(syncElementSelector).forEach((syncElement) => {
            syncElement.style.opacity = '1';
        });
    }

    // Check if the last element is visible in the viewport
    const lastElementRect = lastElement.getBoundingClientRect();
    const isLastElementVisible = lastElementRect.bottom + window.scrollY > window.scrollY &&
        lastElementRect.top + window.scrollY < window.scrollY + window.innerHeight;

    if (isLastElementVisible) {
        // Keep the last element always visible
        lastElement.style.opacity = '1';

        // Keep synced elements also visible
        const dataReveal = lastElement.getAttribute('data-reveal-on-scroll');
        const syncElementSelector = `[data-reveal-on-scroll-sync="${dataReveal}"]`;
        document.querySelectorAll(syncElementSelector).forEach((syncElement) => {
            syncElement.style.opacity = '1';
        });
    }
}

// Attach the function to the scroll event
window.addEventListener('scroll', handleElementVisibility);

// Initialisatie: stel rootMargin en opacity correct in
revealOnScrollElements.forEach((element) => {
    // Optionele attributen uitlezen
    const margin = parseInt(element.getAttribute('data-reveal-on-scroll-margin') || '50', 10);

    // Bereken rootMargin
    const windowHeight = window.innerHeight;
    const rootMargin = `-${windowHeight / 2 - margin}px 0px ${windowHeight / 2 - margin}px 0px`;

    // Initialiseer observer
    element.style.opacity = '0';
    observer.observe(element, {
        rootMargin: rootMargin,
    });
});

// Scroll en resize event listeners toevoegen voor eerste element
window.addEventListener('scroll', handleElementVisibility);
window.addEventListener('resize', handleElementVisibility);
