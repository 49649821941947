import axios from 'axios';
window.axios = axios;

import './scripts/forms';

import './scripts/cookie-consent';
import './scripts/cookie-consent-banner';

import './scripts/dev-seo';
import './scripts/lazysizes';
import './scripts/mobile-menu';
import './scripts/close-menu-on-scroll-link';
import './scripts/popup';
import './scripts/reveal-on-scroll';
import './scripts/sliders';
import './scripts/toggle';
import './scripts/tooltip';
