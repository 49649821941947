/*
 * Name: Tooltip.js
 * Description: Default positioning with data-position/direction tag
 * Version: v1.0.0
 */

window.addEventListener('click', function(e) {
    tooltipPosition();
});

function tooltipPosition() {
    window.g_windowWidth = document.documentElement.clientWidth;
    window.g_windowHeight = document.documentElement.clientHeight;

    const elems = document.querySelectorAll('.tooltip');
    const $elems = [].slice.call(elems);

    $elems.map((element) => {
        let elementWidth = element.offsetWidth;
        let elementHeight = element.offsetHeight;
        let elementRect = element.getBoundingClientRect();

        // Positioning
        element.classList.remove('tooltip-align-right');
        element.classList.remove('tooltip-align-bottom');

        if ((elementRect.right) > window.g_windowWidth) {
            element.classList.add('tooltip-align-right');
        }

        if ((elementRect.bottom) > window.g_windowHeight) {
            element.classList.add('tooltip-align-bottom');
        }

        // Debug
        if(window.g_dev === true) {
            console.log('Top: ' + elementRect.top);
            console.log('Bottom: ' + elementRect.bottom);
            console.log('Left: ' + elementRect.left);
            console.log('Right: ' + elementRect.right);
            console.log('Screen width: ' + window.g_windowWidth);
            console.log('Screen height: ' + elementHeight);
        }
    });
}
