/*
 * Name: Popup.js
 * Description: Show pop-up `data-popup-id` once or multiple times based on `data-popup-times`
 * Version: v1.0.0
 */

let popupElements = document.querySelectorAll('[data-popup]');

if (popupElements.length) {
    [].forEach.call(popupElements, function (popupElement) {

        // Set variables
        let popupInclude = popupElement.getAttribute('data-popup-include');
        let popupExclude = popupElement.getAttribute('data-popup-exclude');
        let popupTimes = popupElement.getAttribute('data-popup-times');
        let popupId = popupElement.getAttribute('data-popup-id');

        // Check `data-popup-times`
        if (popupTimes !== '') {

            // Start count when empty
            if(localStorage.getItem(popupId) === null) {
                localStorage.setItem(popupId, '1');
            }

            // Show popup when count is less or the same as `data-popup-times`
            if (localStorage.getItem(popupId) <= popupTimes) {
                popupElement.classList.add('active');
                popupElement.parentElement.classList.add('active');

                localStorage.setItem(popupId,
                    (parseInt(localStorage.getItem(popupId), 10) + 1).toString(10)
                );
            }

        // Always show popup
        } else {
            popupElement.classList.add('active');
            popupElement.parentElement.classList.add('active');
        }

        // Debug
        if(window.g_dev === true) {
            console.log(localStorage.getItem(popupId));
        }

    });

}
