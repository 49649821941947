/**
 * Close mobile menu on menu-element click when navigating to local anchor
 */
const mobileMenuElements = document.querySelectorAll('.mobile-menu-section a[href^="#"]');
const $mobileMenuElements = [].slice.call(mobileMenuElements);
$mobileMenuElements.map((element) => {
    element.addEventListener('click', function (e) {
        document.querySelector('html').classList.remove('active-mobile-menu');
    });
});
