(function (global) {
    let cookieConsent = {};

    cookieConsent.get = function () {
        return BeInteractive.cookieConsent.value;
    };

    cookieConsent.set = function (value) {
        return new Promise(function (resolve, reject) {
            axios.post('ajax/cookie-consent', {
                    value: value,
                })
                .then(function (response) {
                    let data = response.data;
                    BeInteractive.cookieConsent.value = data.value;
                    resolve(data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    };

    cookieConsent.responded = function () {
        return this.get() !== null;
    };

    cookieConsent.accepted = function () {
        return this.responded() && this.get();
    };

    cookieConsent.denied = function () {
        return this.responded() && !this.get();
    };

    cookieConsent.getCookieName = function () {
        return BeInteractive.cookieConsent.cookie_name;
    };

    global.cookieConsent = cookieConsent;
})(window);
