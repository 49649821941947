/* Cookie consent */
(function () {
    const $cookieConsent = document.querySelector("[data-cookie-consent]");

    document.addEventListener('DOMContentLoaded', function () {

        if (!$cookieConsent) {
            return;
        }

        // buttons
        let $cookieConsentAccept = $cookieConsent.querySelector('[data-cookie-consent="accept"]');
        let $cookieConsentDeny = $cookieConsent.querySelector('[data-cookie-consent="deny"]');
        let $cookieConsentClose = $cookieConsent.querySelector('[data-cookie-consent="close"]');

        // accept cookies
        if ($cookieConsentAccept) {
            $cookieConsentAccept.addEventListener('click', function(event) {
                event.preventDefault();
                accept();
            }, false);
        }

        // deny cookies
        if ($cookieConsentDeny) {
            $cookieConsentDeny.addEventListener('click', function(event) {
                event.preventDefault();
                deny();
            }, false);
        }

        // close consent banner
        if ($cookieConsentClose) {
            $cookieConsentClose.addEventListener('click', function(event) {
                event.preventDefault();
                close();
            }, false);
        }
    });


    function accept() {
        respond(true);
    }

    function deny() {
        respond(false);
    }

    function close() {
        $cookieConsent.parentNode.removeChild($cookieConsent);
    }

    function respond(value) {
        $cookieConsent.style.display = 'none';

        cookieConsent.set(value)
            .then(function (data) {
                // set bGoogle Analytics anonymizeIp value
                if (window.ga) {
                    ga("set", "anonymizeIp", !data.value);
                }

                // reload page to affect cookie settings
                if (data.reload) {
                    location.reload();
                    return;
                }

                // remove consent banner
                close();
            })
            .catch(function (response) {
                $cookieConsent.style.display = 'block';

                if (!response.responseJSON) {
                    return;
                }

            });
    }
})();
