import Splide from '@splidejs/splide';

// Defaults
Splide.defaults = {
    type: 'slider',
    lazyLoad: 'nearby',
    preloadPages: 0,
    arrows: 'slider',
    pagination: false,
    autoplay: false,
    reducedMotion: true,
    autoHeight: true,
    perPage: 1,
    gap: '2rem'
}

// Gallery
if(typeof(document.querySelector('.slide-gallery')) != 'undefined' && document.querySelector('.slide-gallery') != null) {
    const splideGallery = document.querySelectorAll('.slide-gallery.splide');

    for (let i = 0; i < splideGallery.length; i++) {
        const splide = new Splide(splideGallery[i], {
            perPage: 4,
            breakpoints: {
                992: {
                    perPage: 3,
                },
                576: {
                    perPage: 2,
                },
            }
        });

        splide.mount();
    }
}

// Inline
if(typeof(document.querySelector('.splide-data.splide')) != 'undefined' && document.querySelector('.splide-data.splide') != null) {
    const splideData = document.querySelectorAll('.splide-data');

    for (let i = 0; i < splideData.length; i++) {
        const splide = new Splide(splideData[i], {
            // Specific options
        });

        splide.mount();
    }
}

// Reviews
if(typeof(document.querySelector('.slide-reviews')) != 'undefined' && document.querySelector('.slide-reviews') != null) {
    const splideReviews = document.querySelectorAll('.slide-reviews.splide');

    for (let i = 0; i < splideReviews.length; i++) {
        const splide = new Splide(splideReviews[i], {
            perPage: 1,
            arrows: false,
            pagination: true,
        });

        splide.mount();
    }
}

// Usps
if(typeof(document.querySelector('.slide-usps-why')) != 'undefined' && document.querySelector('.slide-usps-why') != null) {
    const splideUspsWhy = document.querySelectorAll('.slide-usps-why.splide');

    for (let i = 0; i < splideUspsWhy.length; i++) {
        const splide = new Splide(splideUspsWhy[i], {
            perPage: 4,
            perMove: 1,
            arrows: false,
            pagination: true,
            autoplay: true,
            breakpoints: {
                990: {
                    perPage: 3,
                },
                740: {
                    perPage: 2,
                },
                560: {
                    perPage: 1,
                },
            }
        });

        splide.mount();
    }
}

if(typeof(document.querySelector('.slide-usp-ticker')) != 'undefined' && document.querySelector('.slide-usp-ticker') != null) {
    const splideUspTicker = document.querySelectorAll('.slide-usp-ticker.splide');

    for (let i = 0; i < splideUspTicker.length; i++) {
        const splide = new Splide(splideUspTicker[i], {
            perPage: 3,
            rewind: true,
            autoplay: true,
            speed: 3000,
            interval: 5000,
            pauseOnHover: false,
            arrows: false,
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 2,
                },
                990: {
                    perPage: 1,
                },
            }
        });

        splide.mount();
    }
}
